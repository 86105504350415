<template>
  <BModal
    id="confirmationCancel"
    ref="confirmationCancel"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-1">
        Batal Bayar Invoice
      </div>
      <p class="text-black">
        Kamu yakin mau membatalkan invoice ini?
        <br>
        Invoice ini akan terhapus jika kamu menyetujui Pembatalan
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="$bvModal.hide('confirmationCancel')"
        >
          Kembali
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 160.5px"
          block
          @click="cancel()"
          :disabled="loading"
        >
          Batal bayar
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async cancel() {
      this.loading = true
      const url = `/v1/invoices/${this.$route.params.id}/delete`
      await komtimAxiosIns.delete(url)
        .then(res => {
          this.loading = false
          const text = 'Berhasil membatalkan invoice'

          alertSuccess(text)
          this.$bvModal.hide('confirmationCancel')
          this.$router.back()
        })
        .catch(err => {
          this.loading = false
          alertError(err)
        })
    },
  },
}
</script>
